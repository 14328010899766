import React, { Component } from 'react'
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import '../Portfolio1.css'; 


const TabOne = [
    {
        image: 'samsung-brandcart',
        bigImage: '../img/portfolio1/main/dp-portfolio-samsung-brandcart.png',
        category: 'Magento',
        websiteName:'SAMSUNG BRANDCART',
        title: 'An ecommerce website',
        siteurl:'https://samsung.brandcart.co.ke/'
    },
    {
        image: 'thewindowdepot',
        bigImage: '../img/portfolio1/main/dp-portfolio-thewindowdepot.png',
        category: 'Magento',
        websiteName:'THE WINDOW DEPOT',
        title: 'An ecommerce website',
		siteurl:'https://thewindowdepot.com/'
    },
    {
        image: 'pyurvana',
        bigImage: '../img/portfolio1/main/dp-portfolio-pyurvana.png',
        category: 'Magento',
        title: 'An ecommerce website',
        websiteName:'PYURVANA',
		siteurl:'https://pyurvana.com/'
    },
    {
        image: 'deptstore',
        bigImage: '../img/portfolio1/main/dp-portfolio-deptstore.png',
        category: 'Magento',
        title: 'An ecommerce website',
        websiteName:'DEPTSTORE',
		siteurl:'http://dept.store/'
    },
    {
        image: 'khaleejtools',
        bigImage: '../img/portfolio1/main/dp-portfolio-khaleejtools.png',
        category: 'Magento',
        websiteName:'KHALEEJTOOLS',
        title: 'An ecommerce website',
		siteurl:'https://www.khaleejtools.com/'
    },
    {
        image: 'fabercart',
        bigImage: '../img/portfolio1/main/dp-portfolio-fabercart.png',
        category: 'Magento',
        websiteName:'FABERCART',
        title: 'An ecommerce website',
		siteurl:'https://faberindia.com/'
    },
    {
        image: 'cuffsnlashes',
        bigImage: '../img/portfolio1/main/dp-portfolio-cuffsnlashes.png',
        category: 'Magento',
        title: 'An ecommerce website',
        websiteName:'CUFFSNLASHES',
		siteurl:'https://cuffsnlashes.com/'
    },
    {
        image: 'tosall',
        bigImage: '../img/portfolio1/main/dp-portfolio-tosall.png',
        category: 'Codeigniter',
        websiteName:'TOSALL',
        title: 'This is a chatbot admin panel with front side',
		siteurl:'https://www.tosall.com/'
    },

    {
        image: 'wecharge',
        bigImage: '../img/portfolio1/main/dp-portfolio-wecharge.png',
        category: 'PHP',
        websiteName:'WECHARGE',
        title: 'Fuel and Diesel refueliing website with four panel and mobile app',
		siteurl:'https://wecharge.co.in/'
    },
    {
        image: 'tipster_challenge',
        bigImage: '../img/portfolio1/main/dp-portfolio-tipster_challenge.png',
        category: 'PHP',
        websiteName:'TIPSTER CHALLENGE',
        title: 'This is a Global and Innovative FREE Horse Racing, Competition based on UK Horse Racing',
		siteurl:'http://tipsterchallenge.com/'
    },

    {
        image: 'whatsdoingapp',
        bigImage: '../img/portfolio1/main/dp-portfolio-whatsdoingapp.png',
        category: 'Codeigniter',
        websiteName:'WHATSDOINGAPP',
        title: 'This is an admin panel and API developed for mobile app development',
		siteurl:'http://admindev.whatsdoingapp.com/admin/login'
    },
    {
        image: 'palmspringscarstorage_admin',
        bigImage: '../img/portfolio1/main/dp-portfolio-palmspringscarstorage_admin.png',
        category: 'Codeigniter',
        websiteName:'PALMSPRINGSCARSTORAGE ADMIN',
        title: 'This is an admin panel to access and control all the car which are stored',
		siteurl:'https://palmspringscarstorage.net/admin/'
    },
    {
        image: 'true-opinion',
        bigImage: '../img/portfolio1/main/dp-portfolio-true-opinion.png',
        category: 'Laravel',
        websiteName:'TRUE-OPINION',
        title: 'This is an PAID SURVEY WEBSITE with admin panel (Laravel)',
		siteurl:'https://www.true-opinion.com/'
    },
    {
        image: 'acap',
        bigImage: '../img/portfolio1/main/dp-portfolio-acap.png',
        category: 'Wordpress',
        websiteName:'ACAP | Australian College of Applied Professions',
        title: 'Australian College of Applied Professions',
		siteurl:'https://www.acap.edu.au/' 
    },
    {
        image: 'saeeduau',
        bigImage: '../img/portfolio1/main/dp-portfolio-saeeduau.png',
        category: 'Wordpress',
        websiteName:'SAE CREATIVE MEDIA INSTITUTE',
        title: 'FOR YOUR CREATIVE CAREER BEGINS NOW',
		siteurl:'https://sae.edu.au/'
    },
    {
        image: 'balajimariline',
        bigImage: '../img/portfolio1/main/dp-portfolio-balajimariline.png',
        category: 'Wordpress',
        websiteName:'BALAJIMARILINE',
        title: 'servicing the Indian and foreign exporters and Importers',
		siteurl:'https://www.balajimariline.com/'
    },
    {
        image: 'ellasbubbles',
        bigImage: '../img/portfolio1/main/dp-portfolio-ellasbubbles.png',
        category: 'Wordpress',
        title: 'An ecommerce website',
        websiteName:'ELLASBUBBLES',
		siteurl:'https://ellasbubbles.com/'
    },
    {
        image: 'mensfitclub',
        bigImage: '../img/portfolio1/main/dp-portfolio-mensfitclub.png',
        category: 'Wordpress',
        websiteName:'MENSFITCLUB',
        title: 'This is related about health and gym',
		siteurl:'https://www.mensfitclub.com/'
    },
    {
        image: 'wla',
        bigImage: '../img/portfolio1/main/dp-portfolio-wla.png',
        category: 'Wordpress',
        websiteName:'Women Leadership Australia',
        title: 'This is related about Women Leadership Australia',
		siteurl:'https://www.wla.edu.au/'
    }

]

class Portfolioprojects extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab1: 0,
            isOpen: false,
        };
    }
    render() {
        const { tab1, isOpen } = this.state;
        return (
           <>
                 <div className="row row--35">
                        {TabOne.map((value , index) => (
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={index}>
                                {isOpen && (
                                    <Lightbox
                                        mainSrc={TabOne[tab1].bigImage}
                                        nextSrc={TabOne[(tab1 + 1) % TabOne.length]}
                                        prevSrc={TabOne[(tab1 + TabOne.length - 1) % TabOne.length]}
                                        onCloseRequest={() => this.setState({ isOpen: false })}
                                        onMovePrevRequest={() =>
                                        this.setState({
                                            tab1: (tab1 + TabOne.length - 1) % TabOne.length,
                                        })
                                        }
                                        onMoveNextRequest={() =>
                                            this.setState({
                                                tab1: (tab1 + 1) % TabOne.length,
                                            })
                                        }
                                        imageLoadErrorMessage = 'Image Loading ...'
                                        enableZoom={false}
                                    />
                                )}
                                <div className="item-portfolio-static">
                                    <div onClick={() => this.setState({ isOpen: true, tab1: index })}>
                                        <div className="portfolio-static case-item align-center mb60">
                                            <div className="thumbnail-inner case-item__thumb">
                                                <div className="thumbnails">
                                                    <a href="#portfolio-details">
                                                        <img src={`../img/portfolio1/main/dp-portfolio-${value.image}.png`} alt="Portfolio img"/>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <div className="inner">
                                                <p><a href={value.siteurl} className='case-item__title'  target="_blank" rel="noopener noreferrer">{value.websiteName}</a></p>
                                                    <h6 className='case-item__title' >{value.title}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
           </>
        )
    }
}

export default Portfolioprojects;