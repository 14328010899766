import { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import Footer from '../Component/Footer';
import Header from '../Component/Header';
import Newsletters from './Newsletters';
import Homeblogslider from './Homeblogslider';
import Slide from './Slide';
import Clientslider from './Clientslider';
import Valuableclients from './Valuableclients';
import {Helmet} from "react-helmet";
// import Slider from '../Component/Slider';
import Slider from 'react-slick';
import Ourproject from '../Component/Ourproject'

const Home =()=>{
  
  const pscsstyle = {
    backgroundColor:"#060607",
  }
    return(
        <>
	
        <div>

  
  {/* Right-menu */}
  <div className="content-wrapper">
    <div className="header-spacer" />
    {/* <Slider/>  */}
    <Slide/>
    {/* Info-Box */}
    <div className="container info-boxes pt-6">
      <div className="row">
      <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
          <div className="info-box--standard" data-mh="info-boxes">
            <div className="info-box-image">
              <img src="img/info-box30.png" alt="image" />
            </div>
            <div className="info-box-content">
               <p className="text">Growth in 
              </p>
              <h5 className="info-box-title">Web Design</h5>
             
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
          <div className="info-box--standard" data-mh="info-boxes">
            <div className="info-box-image">
              <img src="img/info-box29.png" alt="image" />
            </div>
            <div className="info-box-content">
               <p className="text">Growth in 
              </p>
              <h5 className="info-box-title">Web Development</h5>
             
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
          <div className="info-box--standard" data-mh="info-boxes">
            <div className="info-box-image">
              <img src="img/info-box1.png" alt="image" />
            </div>
            <div className="info-box-content">
               <p className="text">Growth in 
              </p>
              <h5 className="info-box-title">keyword ranking </h5>
             
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
          <div className="info-box--standard" data-mh="info-boxes">
            <div className="info-box-image">
              <img src="img/info-box2.png" alt="image" />
            </div>
            <div className="info-box-content">
            <p className="text">Growth in 
              </p>
              <h5 className="info-box-title">Link Building</h5>
              
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
          <div className="info-box--standard" data-mh="info-boxes">
            <div className="info-box-image">
              <img src="img/info-box3.png" alt="image" />
            </div>
            <div className="info-box-content">
            <p className="text">Growth in 
              </p>
              <h5 className="info-box-title">SEO Optimization </h5>
              
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
          <div className="info-box--standard" data-mh="info-boxes">
            <div className="info-box-image">
              <img src="img/info-box4.png" alt="image" />
            </div>
            <div className="info-box-content">
            <p className="text">Growth in 
              </p>
              <h5 className="info-box-title">Social Marketing</h5>
             
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
          <div className="info-box--standard" data-mh="info-boxes">
            <div className="info-box-image">
              <img src="img/info-box5.png" alt="image" />
            </div>
            <div className="info-box-content">
            <p className="text">Growth in 
              </p>
              <h5 className="info-box-title">Advanced Technology</h5>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
          <div className="info-box--standard" data-mh="info-boxes">
            <div className="info-box-image">
              <img src="img/info-box6.png" alt="image" />
            </div>
            <div className="info-box-content">
            <p className="text">Growth in </p>
              <h5 className="info-box-title">SEO Analytics</h5>
              
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* ... End Info-Box */}
    {/* SEO-Score */}
    {/* <div className="container-fluid">
      <div className="row">
        <div className="seo-score scrollme">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-lg-offset-2 col-md-8 col-md-offset-2 col-xs-12 col-sm-12">
                <div className="seo-score-content align-center">
                  <div className="heading align-center">
                    <h4 className="h1 heading-title">Our Responsive Website</h4>
                    <p className="heading-text">Check your website’s is responsive for free!</p>
                  </div>
                  <div className="seo-score-form">
                    <form className="seo-score-form input-inline" method="post" action="send_mail.php">
                      <div className="row">
                        <div className="col-lg-8 no-padding col-md-12 col-xs-12 col-sm-12">
                          <input name="permalink" className="input-dark site" required="required" placeholder="Type in your Website URL" />
                        </div>
                        <div className="col-lg-4 no-padding col-md-12 col-xs-12 col-sm-12">
                          <input name="email" className="input-dark e-mail" required="required" placeholder="Your Email" type="email" />
                        </div>
                      </div>
                      <button className="btn btn-medium btn--green btn-hover-shadow">
                        <span className="text">Check Up!</span>
                        <span className="semicircle" />
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="images">
            <img src="img/seoscore1.png" alt="image" />
            <img src="img/seoscore2.png" alt="image" />
            <img src="img/seoscore3.png" alt="image" />
          </div>
        </div>
      </div>
    </div> */}
    {/* ... End SEO-Score */}
    {/* Offers */}
    <div className="container">
      <div className="row pb-6 mb-6 pt-6">
        <div className="col-lg-12">
          <div className="offers">
            <div className="row">
              <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12">
                <div className="heading">
                  <h4 className="h1 heading-title">We Offer a Full Range of Digital
                    Marketing Services!</h4>
                  <div className="heading-line">
                    <span className="short-line" />
                    <span className="long-line" />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="heading">
                  <p className="heading-text">Our professional digital marketers formulate data-driven and result-oriented strategies that boosts
                  </p>
                </div>
                <ul className="list list--secondary">
                  <li>
                    <i className="seoicon-check" />
                    <p>Be In Touch With The Customers</p>
                  </li>
                  <li>
                    <i className="seoicon-check" />
                    <p>Target The Right Leads</p>
                  </li>
                  <li>
                    <i className="seoicon-check" />
                    <p>Get Customer Feedback For Constant Improvement</p>
                  </li>
                  <li>
                    <i className="seoicon-check" />
                    <p>Analyze Your Growth And Performance</p>
                  </li>
				  <li>
                    <i className="seoicon-check" />
                    <p>Avail The Benefit Of Omni-Channel Reach</p>
                  </li>
				  <li>           
                    <i className="seoicon-check" />
					 <p>Change The Current Strategies For Better</p>
                  </li>
                </ul>
                <Link to="/services" className="btn btn-medium btn-small btn-border c-primary">
                  <span className="text">Learn More</span>
                  <span className="semicircle" />
                </Link>
                <Link to="/about" className="btn btn-medium btn--primary btn-small btn-hover-shadow">
                  <span className="text">Get a Quote</span>
                  <span className="semicircle" />
                </Link>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="offers-thumb">
                  <img src="img/offers1.png" alt="offers" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* ... End Offers */}
    {/* Our-video */}
    <div className="container-fluid">
      <div className="row">
        <div className="our-video js-equal-child">
          <div className="col-lg-6 col-md-5 col-sm-12 col-xs-12 no-padding res ">
		       <div className="overlay" />
            <div className="video theme-moule">
              <div className="video-thumb">
                  
                <iframe title="vimeo-player" src="https://player.vimeo.com/video/660606209?h=6019503020" className="home_iframe" width="620" height="360"  frameBorder="0" allowFullScreen>
                 
                </iframe>
                 <a href="https://player.vimeo.com/video/660606209?h=6019503020" className="video-control js-popup-iframe">
                  <img src="svg/video-control.svg" alt="go" />
                 </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-lg-offset-1 col-md-6 col-md-offset-1  col-sm-12 col-xs-12 no-padding res">
            <div className="content theme-module centered-on-mobile ">
              <div className="heading">
                <h4 className="h1 heading-title mt-2">Watch Our Video</h4>
                <div className="heading-line">
                  <span className="short-line" />
                  <span className="long-line" />
                </div>
                <p className="heading-text">Plux Infosystems is an India based web designing, Internet marketing, and mobile application development company with a unique approach towards the web. For some of our customers, we are an internet marketing company who can help their website rank better in Google, to others we are web developers while to some others we are the people who can create applications they want to see the build. We can’t get enough of web and aspire to help each of our clients do more with it.
                </p>
              </div>
              <Link to="/about" className="btn btn-medium btn--secondary">
                <span className="text">About Us</span>
                <span className="semicircle" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* ... End Offers */}
    {/* Background-mountains */}
    <div className="container-fluid">
      <div className="row">
        <div className="background-mountains pt-6 pb-6 scrollme">
          <div className="images">
            <img src="img/mountain1.png" alt="mountain" />
            <img src="img/mountain2.png" alt="mountain" />
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-lg-offset-2 col-md-8 col-md-offset-2 col-sm-12 col-xs-12">
                <div className="heading align-center">
                  <p className="heading-text"></p>
                  <h4 className="h1 heading-title">Best of web devlopment</h4>
                  <div className="heading-line">
                    <span className="short-line" />
                    <span className="long-line" />
                  </div>
                  
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <div className="info-box--standard-centered">
                  <div className="info-box-image">
                    <img src="img/info-box7.png" alt="image" />
                  </div>
                  <div className="info-box-content">
                    <h4 className="info-box-title">Objective</h4>
                    <p className="text">Experienced Web Developer seeking opportunity to bring knowledge of programming, design
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <div className="info-box--standard-centered">
                  <div className="info-box-image">
                    <img src="img/info-box8.png" alt="image" />
                  </div>
                  <div className="info-box-content">
                    <h4 className="info-box-title">Strategy</h4>
                    <p className="text">Gather a good team, Know your users and Always test and validate your projects
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <div className="info-box--standard-centered">
                  <div className="info-box-image">
                    <img src="img/info-box9.png" alt="image" />
                  </div>
                  <div className="info-box-content">
                    <h4 className="info-box-title">Technology</h4>
                    <p className="text">We are using latest technology which is demand in the world market 
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <div className="info-box--standard-centered">
                  <div className="info-box-image">
                    <img src="img/info-box10.png" alt="image" />
                  </div>
                  <div className="info-box-content">
                    <h4 className="info-box-title">Analytics</h4>
                    <p className="text">One of the main roles of a business analyst is  diving into what clients actually do 
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row align-center">
              <div className="btn-block">
                <Link to="/services" className="btn btn-medium btn--dark">
                  <span className="text">More Info</span>
                  <span className="semicircle" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* End Background-mountains */}
    {/* Counters */}
    <div className="container-fluid">
      <div className="row bg-green-color">
        <div className="container">
          <div className="row">
            <div className="counters">
              <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                <div className="counter-item">
                  <div className="counter-numbers counter">
                    <span data-speed={2000} data-refresh-interval={3} data-to={96} data-from={2}>96%</span>
                    {/* <div className="units">%</div> */}
                  </div>
                  <span className="counter-title">Client Retention</span>
                  <div className="counter-line">
                    <span className="short-line" />
                    <span className="long-line" />
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                <div className="counter-item">
                  <div className="counter-numbers counter">
                    <span data-speed={2000} data-refresh-interval={3} data-to={10} data-from={0}>10</span>
                  </div>
                  <span className="counter-title">Years of Service</span>
                  <div className="counter-line">
                    <span className="short-line" />
                    <span className="long-line" />
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                <div className="counter-item">
                  <div className="counter-numbers counter">
                    <span data-speed={2000} data-refresh-interval={3} data-to={70} data-from={2}>230+</span>
                    {/* <div className="units">+</div> */}
                  </div>
                  <span className="counter-title">Professionals</span>
                  <div className="counter-line">
                    <span className="short-line" />
                    <span className="long-line" />
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                <div className="counter-item">
                  <div className="counter-numbers counter">
                    <span data-speed={2000} data-refresh-interval={3} data-to={690} data-from={400}>690+</span>
                    {/* <div className="units">+</div> */}
                  </div>
                  <span className="counter-title">Satisfied Clients</span>
                  <div className="counter-line">
                    <span className="short-line" />
                    <span className="long-line" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* End Counters */}
    {/* Recent-case */}
    <Ourproject/>
    {/* End Recent-case */}
    {/* Testimonial-slider */}
    <div className="container-fluid">
      <div className="row">
        <div className="testimonial-slider scrollme">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                <div className="heading">
                  <h4 className="h1 heading-title">Happy Clients
                    About Us</h4>
                  <div className="heading-line">
                    <span className="short-line bg-yellow-color" />
                    <span className="long-line bg-yellow-color" />
                  </div>
                  <p className="heading-text c-white">Clarity is also a dynamic process for readers.
                  </p>
                </div>
                <div className="signature js-animate-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width={200} height={60}>
                    <path fill="none" stroke="#2f2c2c" strokeWidth="1.4" d="M24.78 42.5C11.28 41.06.96 36 1.16 26.86 1.62 11.6 16.76 3.6 28.13 3.25c5.44-.16 9.8 2.38 10.2 5.8.86 7.66-19.23 15.62-19.23 15.62s17.86-6.57 24.66 2.2c2.8 3.6-11.8 10.65-11.8 10.65s-8.8 3.35-7.65-.24c1.78-5.52 16.8-10.1 18.42-10.3 3.17-.4 7.1-.4 8 .12 1.33.78-1.7 4.14-.94 4.86.76.72 3.82-2.55 3.94-1.97.12.57-.64 2.63.58 3 1.2.38 2.3-1.1 4.28-1.5 1.97-.4 1.83-.44 3.6-.12 1.76.32 2.1.64 3.47 1.4 1.36.74.86 2.22 1.96 1.6 1.1-.6 1.43-4.16 2.44-4.04 1.02.1.03 5.38 1.63 4.5 1.6-.85 4.74-7.97 4.74-7.97s-.82 4.04 0 3.93c3.14-.4 8.12-2.57 14.48-3.36 6.37-.78 8.67-.35 11 .24 1.92.48 2.67 2.3 2.67 2.3" strokeLinecap="round" strokeLinejoin="round" />
                    <path fill="none" stroke="#2f2c2c" strokeWidth="1.4" d="M113.7 15.75s-1.1-2.78-3.6-2.08c-5.83 1.62-16.2 7.68-16.44 15.63-.46 16.3 21.1 11.14 22.7 11 13.6-1.2 17.38-9.96 17.36-14.48-.06-14.3-19.07-10.3-21.07-10.07-7.9.92-10.48 1.57-10.48 1.57" />
                    <path fill="none" stroke="#2f2c2c" strokeWidth="1.4" d="M119.7 1.05c-2.53 13.9-5.1 27.83-2.53 41.44" strokeLinecap="round" />
                    <path fill="none" stroke="#2f2c2c" strokeWidth="1.4" d="M133.6 27.8c-6.62-.42-12.6.37-17.36 3.35" />
                    <path fill="none" stroke="#2f2c2c" strokeWidth="1.4" d="M131.52 32.77s3.95-.13 8.34-.93c2.4-.43 5-1.73 7.87-1.85 2.87-.12 1.74 1.44 3.6 1.38 4.62-.14 7.98-.3 14.92-.7 6.95-.4 9.76-2.76 12.86-.92 1.7 1 1.4 3.13 1.4 3.13" strokeLinecap="round" />
                    <path fill="none" stroke="#2f2c2c" strokeWidth="1.4" d="M153.52 31.03s2.27-2.74-.7-3.35c-3.36-.7-3.7 2.43-3.7 2.43" />
                  </svg>
                </div>
              </div>
              <div className="col-lg-7 col-lg-offset-1 col-md-8 col-sm-12 col-xs-12">
                <div className="testimonial-item">
               
                  {/* Slider main container */}
                  <div className="swiper-container testimonial__thumb overflow-visible" data-effect="fade" data-loop="false">
                      < Clientslider/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="testimonial-img">
            <img src="img/testimonial2.png" alt="image" />
          </div> */}
        </div>
      </div>
    </div>
    {/* End Testimonial-slider */}

    {/* Post-slider */}


      <Homeblogslider />



    {/* End Post-slider */}
    {/* Clients */}
    <div className="section">
      <div className="client-carousel pt-6 pb-3">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-lg-offset-3 col-md-6 col-md-offset-3 col-sm-12 col-xs-12">
              <div className="heading align-center">
                <h4 className="h1 heading-title">Our Valuable Clients</h4>
                <div className="heading-line">
                  <span className="short-line" />
                  <span className="long-line" />
                </div>
                <p className="heading-text"></p>
              </div>
            </div>
          </div>
          <div className="theme-module clients-slider-module">
            <Valuableclients/>
            {/* <div className="swiper-container pagination-bottom" data-show-items={6}>
              <div className="swiper-wrapper">
                <div className="swiper-slide client-item">
                  <a href="http://web.kailashchandra.com/cashewprotect/" target="_blank" className="client-image">
                    <img src="img/logo-cashew.png" alt="logo" className="hover" />
                  </a>
                </div>
                <div className="swiper-slide client-item">
                  <a href="http://surveys.authenticopinion.com/clientpanel" target="_blank" className="client-image">
                    <img src="img/logo-trueopinion.png" alt="logo" className="hover" />
                  </a>
                </div>
				
				        <div className="swiper-slide client-item">
                  <a href="https://web.kailashchandra.com/tosall_client/" target="_blank" className="client-image">
                    <img src="img/tosall-logo-plux.png" alt="logo" className="hover" />
                  </a>
                </div>
				
                <div className="swiper-slide client-item">
                  <a href="https://tipsterchallenge.com/pluxinfo_2021/" target="_blank" className="client-image">
                    <img src="img/logo-tipster.jpg" alt="logo" className="hover" />
                  </a>
                </div>
				
				
                <div className="swiper-slide client-item">
                  <a href="https://www.wecharge.co.in/" target="_blank" className="client-image">
                    <img src="img/logo-wechage.png" alt="logo" className="hover" />
                  </a>
                </div>
                <div className="swiper-slide client-item">
                  <a href="https://web.kailashchandra.com/pscs/front" target="_blank" className="client-image">
                    <img src="img/logo-pscs.png" alt="logo" className="hover" style={pscsstyle}/>
                  </a>
                </div>
              </div>

              <svg className="btn-next">
                <use xlinkHref="#arrow-right" />
              </svg>
              <svg className="btn-prev">
                <use xlinkHref="#arrow-left" />
              </svg>
            </div> */}
          </div>
        </div>
      </div>
    </div>
    {/* End Clients */}
    {/* Subscribe Form */}
    <Newsletters />
    <CookieConsent
  location="bottom"
  buttonText="I Agree."
  cookieName="myAwesomeCookieName2"
  style={{ background: "#2B373B",textAlign:"center", fontSize: "16px"}}
  buttonStyle={{ background: "#00a99d" ,color: "#ffffff", fontSize: "13px" }}
  expires={150}
>
We use cookies to ensure that we give you the best experience on our website. If you continue to use this site we will assume that you are happy with it.

</CookieConsent>
  </div>
 

</div>
        </>
    )
}
export default Home;