import React, { Component } from 'react';
import { NavLink, Outlet } from "react-router-dom";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import './Portfolio1.css'; 


class TabStyleThree extends Component {
    render() {
        return (
            <div>
              <div className="header-spacer" />
    <div className="stunning-header stunning-header-bg-rose">
      <div className="stunning-header-content">
        <h1 className="stunning-header-title">Our Portfolio</h1>
        <ul className="breadcrumbs">
          <li className="breadcrumbs-item">
            <NavLink to="/">Home</NavLink>
            <i className="seoicon-right-arrow" />
          </li>
          <li className="breadcrumbs-item active">
            <span href="#">Our Portfolio</span>
            <i className="seoicon-right-arrow" />
          </li>
        </ul>
      </div>
    </div>    
    <div className='container'>
      <div className="row medium-padding120">
          <div className="col-lg-12">
            <div className="heading align-center">
                <h4 className="h1 heading-title">We Help Over 80 Companies</h4>
            </div>
            <section  data-id="portfolio">
              <div className="section-inner">
                <div>
                    <div className="row text-center">
                        <div className="col-lg-12">
                            <div className="tablist-inner">
                            <ul className="pv-tab-button text-center mt--0">
                                    <li><NavLink className="pt-trigger"  activeClassName="is-active" to="/portfolio"><span>All Project</span></NavLink></li>
                                    <li><NavLink className="pt-trigger"  activeClassName="is-active" to="/portfolio/magento2" exact><span>Magento2</span></NavLink></li>
                                    <li><NavLink className="pt-trigger"  activeClassName="is-active" to="/portfolio/php" exact><span>PHP</span></NavLink></li>
                                    <li><NavLink className="pt-trigger"  activeClassName="is-active"to="/portfolio/laravel" exact><span>Laravel</span></NavLink></li>
                                    <li><NavLink className="pt-trigger"  activeClassName="is-active" to="/portfolio/codeigniter" exact><span>CodeIgniter</span></NavLink></li>
                                    <li><NavLink className="pt-trigger"  activeClassName="is-active" to="/portfolio/wordpress" exact><span>Wordpress</span></NavLink></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <Outlet />

                </div>
                </div>
            </section>
          </div>
          </div>
       </div>
     </div>
        )
    }
}


export default TabStyleThree
