import React,{useEffect} from "react";
import { BrowserRouter, Routes, Route} from "react-router-dom";
import About from "./Pages/About";
import Carrer from "./Pages/Carrer";
import Getintouch from "./Pages/Getintouch";
import Home from "./Pages/Home";
import Ourteam from "./Pages/Ourteam";
import Portfolio from "./Pages/Portfolio";
import Portfolio1 from "./Pages/Portfolio1";
import Portfolioprojects from './Pages/portfolio/Portfolioprojects';
import Magento2 from './Pages/portfolio/Magento2';
import Php from './Pages/portfolio/Php';
import Laravel from './Pages/portfolio/Laravel';
import Codeigniter from './Pages/portfolio/Codeigniter';
import Wordpress from './Pages/portfolio/Wordpress';
import Services from "./Pages/Services";
import Header from "./Component/Header";
import Footer from "./Component/Footer";
import Ecommerce from "./Pages/Ecommerce";
import Webdevlopment from './Pages/Webdevlopment';
import Seoserv from './Pages/Seoserv';
import WebHost from "./Pages/WebHost";
import Webdesign from "./Pages/Webdesign";
import MagentoDev from "./Pages/MagentoDev";
import MobileApp from './Pages/MobileApp';
import Graphic from './Pages/Graphic';
import Blog from './Pages/Blog';
import Blogdetail from './Pages/BlogDetail';
import BlogMainPage from './Pages/BlogMainPage';
// import ScriptTag from 'react-script-tag';

import ScrollToTop from "./Component/ScrollToTop";

import "./styles.css";

export default function App() {
  
  return (
    <>

<BrowserRouter>
    <ScrollToTop/>
      <Header/>
        <Routes>
            
          <Route exact path="/ecommerce" element={<Ecommerce />}></Route>

          <Route exact path="/seo-services" element={<Seoserv />}></Route>

          <Route exact path="/web-host" element={<WebHost />}></Route>

          <Route exact path="/web-design" element={<Webdesign />}></Route>

          <Route exact path="/Web-development" element={<Webdevlopment />}></Route>

          <Route exact path="/magento-development" element={<MagentoDev />}></Route>

          <Route exact path="/mobile-app" element={<MobileApp />}></Route>
          
          <Route exact path="/about" element={<About />}></Route>

          <Route exact path="/services" element={<Services />}></Route>

          {/* <Route exact path="/portfolio" element={<Portfolio />}></Route> */}

          <Route exact path="/portfolio" element={<Portfolio1 />}>

          <Route path="/portfolio" element={<Portfolioprojects/>} />
                <Route path="/portfolio/magento2" element={<Magento2/>} />
                <Route path="/portfolio/php" element={<Php/>} />
                <Route path="/portfolio/laravel" element={<Laravel/>} />
                <Route path="/portfolio/codeigniter" element={<Codeigniter/>} />
                <Route path="/portfolio/wordpress" element={<Wordpress/>} /> 
          </Route>


          <Route exact path="/ourteam" element={<Ourteam />}></Route>

          {/* <Route exact path="/carrer" element={<Carrer />}></Route> */}

          <Route exact path="/getintouch" element={<Getintouch />}></Route>

          <Route exact path="/graphic" element={<Graphic />}></Route>

          <Route exact path="/blog" element={<Blog />}></Route>

          <Route exact path="/blogs/:id" element={<BlogMainPage />}></Route>

          <Route exact path="/blogdetail" element={<Blogdetail />}></Route>

          <Route exact path="/" element={<Home />}></Route>

          <Route exact path="/home" element={<Home />}></Route>

        </Routes> 
        <Footer/> 
        </BrowserRouter> 
      
      {/* <ScriptTag isHydrating={true} type="text/javascript" src="js/jquery-3.3.1.js"/>
      <ScriptTag isHydrating={true} type="text/javascript" src="js/js-plugins/crum-mega-menu.js"/>
      <ScriptTag isHydrating={true} type="text/javascript" src="js/swiper.jquery.min.js"/>
      <ScriptTag isHydrating={true} type="text/javascript" src="js/js-plugins/waypoints.js"/>
      <ScriptTag isHydrating={true} type="text/javascript" src="js/js-plugins/jquery.drawsvg.js"/>
      <ScriptTag isHydrating={true} type="text/javascript" src="js/js-plugins/jquery-countTo.js"/>
      <ScriptTag isHydrating={true} type="text/javascript" src="js/js-plugins/jquery.mousewheel.js"/>
      <ScriptTag isHydrating={true} type="text/javascript" src="js/js-plugins/jquery.mCustomScrollbar.js"/>
      <ScriptTag isHydrating={true} type="text/javascript" src="js/js-plugins/imagesLoaded.js"/>
      <ScriptTag isHydrating={true} type="text/javascript" src="js/js-plugins/jquery.magnific-popup.js"/>
      <ScriptTag isHydrating={true} type="text/javascript" src="js/js-plugins/jquery.matchHeight.js"/>
      <ScriptTag isHydrating={true} type="text/javascript" src="js/js-plugins/segment.js"/>
      <ScriptTag isHydrating={true} type="text/javascript" src="js/js-plugins/bootstrap.js"/>
      <ScriptTag isHydrating={true} type="text/javascript" src="js/js-plugins/jquery-circle-progress.js"/>
      <ScriptTag isHydrating={true} type="text/javascript" src="js/js-plugins/Headroom.js"/>
      <ScriptTag isHydrating={true} type="text/javascript" src="js/js-plugins/smooth-scroll.js"/>
      <ScriptTag isHydrating={true} type="text/javascript" src="js/js-plugins/jquery.nice-select.js"/>
      <ScriptTag isHydrating={true} type="text/javascript" src="js/js-plugins/fastClick.js"/>
      <ScriptTag isHydrating={true} type="text/javascript" src="js/js-plugins/form-actions.js"/>
      <ScriptTag isHydrating={true} type="text/javascript" src="js/js-plugins/velocity.js"/>
      <ScriptTag isHydrating={true} type="text/javascript" src="js/js-plugins/time-line.js"/>
      <ScriptTag isHydrating={true} type="text/javascript" src="js/js-plugins/ScrollMagic.min.js"/>
      <ScriptTag isHydrating={true} type="text/javascript" src="js/js-plugins/animation.velocity.min.js"/>
      <ScriptTag isHydrating={true} type="text/javascript" src="js/js-plugins/ajax-pagination.js"/>
      <ScriptTag isHydrating={true} type="text/javascript" src="js/js-plugins/donut-chart.js"/>
      <ScriptTag isHydrating={true} type="text/javascript" src="js/js-plugins/isotope.pkgd.min.js"/>
      <ScriptTag isHydrating={true} type="text/javascript" src="js/js-plugins/photo-gallery.js"/>
      <ScriptTag isHydrating={true} type="text/javascript" src="js/js-plugins/ion.rangeSlider.js"/>
      <ScriptTag isHydrating={true} type="text/javascript" src="js/main.js"/> */}

      </>
  );
}
