import React, { Component } from 'react'
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import '../Portfolio1.css'; 

const TabFive = [
    {
        image: 'tosall',
        bigImage: '../img/portfolio1/main/dp-portfolio-tosall.png',
        category: 'Codeigniter',
        websiteName:'TOSALL',
        title: 'This is a chatbot admin panel with front side',
		siteurl:'https://www.tosall.com/'
    },
    {
        image: 'wecharge',
        bigImage: '../img/portfolio1/main/dp-portfolio-wecharge.png',
        category: 'Codeigniter',
        websiteName:'WECHARGE',
        title: 'Fuel and Diesel refueliing website with four panel and mobile app',
		siteurl:'https://wecharge.co.in/'
    },
    {
        image: 'whatsdoingapp',
        bigImage: '../img/portfolio1/main/dp-portfolio-whatsdoingapp.png',
        category: 'Codeigniter',
        websiteName:'WHATSDOINGAPP',
        title: 'This is an admin panel and API developed for mobile app development',
		siteurl:'http://admindev.whatsdoingapp.com/admin/login'
    },
    {
        image: 'palmspringscarstorage_admin',
        bigImage: '../img/portfolio1/main/dp-portfolio-palmspringscarstorage_admin.png',
        category: 'Codeigniter',
        websiteName:'PALMSPRINGSCARSTORAGE ADMIN',
        title: 'This is an admin panel to access and control all the car which are stored',
		siteurl:'https://palmspringscarstorage.net/admin/'
    },
]

class Codeigniter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab5: 0,
            isOpen: false,
        };
    }
    render() {
        const { tab5, isOpen } = this.state;
        return (
           <>
           <div className="row row--35">
                        {TabFive.map((value , index) => (
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={index}>
                                {isOpen && (
                                    <Lightbox
                                        mainSrc={TabFive[tab5].bigImage}
                                        nextSrc={TabFive[(tab5 + 1) % TabFive.length]}
                                        prevSrc={TabFive[(tab5 + TabFive.length - 1) % TabFive.length]}
                                        onCloseRequest={() => this.setState({ isOpen: false })}
                                        onMovePrevRequest={() =>
                                        this.setState({
                                            tab5: (tab5 + TabFive.length - 1) % TabFive.length,
                                        })
                                        }
                                        onMoveNextRequest={() =>
                                            this.setState({
                                                tab5: (tab5 + 1) % TabFive.length,
                                            })
                                        }
                                        imageLoadErrorMessage = 'Image Loading ...'
                                        enableZoom={false}
                                    />
                                )}
                                 <div className="item-portfolio-static">
                                    <div onClick={() => this.setState({ isOpen: true, TabFive: index })}>
                                        <div className="portfolio-static case-item align-center mb60">
                                            <div className="thumbnail-inner case-item__thumb">
                                                <div className="thumbnails">
                                                    <a href="#portfolio-details">
                                                        <img src={`../img/portfolio1/main/dp-portfolio-${value.image}.png`} alt="Portfolio img"/>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <div className="inner">
                                                   <p><a href={value.siteurl} className='case-item__title'  target="_blank" rel="noopener noreferrer">{value.websiteName}</a></p>
                                                    <h6 className='case-item__title' >{value.title}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
           </>
        )
    }
}
export default Codeigniter;