import { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Article from './Article';
import Latestnews from './Latestnews';
import PostCate from './PostCate';
import Socialsec from './Socialsec';
import Blogarticle from './Blogarticle';
import {Link} from 'react-router-dom';
const REACT_APP_BLOG_API = process.env.REACT_APP_BLOG_API;

const BlogMainPage = ()=>{
   
    const api = `${REACT_APP_BLOG_API}`;
    //const [page, setPage] = React.useState<number>(0);
    const [myData1, setMyData1] = useState([]);
    const { id } = useParams();
  
    const getApiDataDetailsfull = async () => {
          const res = await axios.get(`${api}/${id}`);
          setMyData1(res.data);
      };

      useEffect(() => {
        getApiDataDetailsfull();
      }, []);
    return(
        <>
            <div className="header-spacer" />
            <div className="stunning-header stunning-header-bg-lightblue">
                <div className="stunning-header-content">
                    <h1 className="stunning-header-title">Blog</h1>
                    <ul className="breadcrumbs">
                        <li className="breadcrumbs-item">
                            <Link to="/blog">Blogs</Link>
                            <i className="seoicon-right-arrow" />
                        </li>
                        <li className="breadcrumbs-item active">
                            <span>Our Blog</span>
                            <i className="seoicon-right-arrow" />
                        </li>
                    </ul>
                </div>
            </div>
            <div className="container">
                <div className="row medium-padding120">
                    <main className="main">
                        <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">                     
                           <Blogarticle 
                              postDate={myData1?.['date']}
                              postTitle={myData1?.['title']?.['rendered']}
                              postDetail={myData1?.['content']?.['rendered']}
                             />
                        {/*  <div className="blog-details-author">

                                <div className="blog-details-author-thumb">
                                    <img src="img/blog-details-author.png" alt="Author"/>
                                </div>

                              <div className="blog-details-author-content">
                                    <div className="author-info">
                                        <h5 className="author-name">Philip Demarco</h5>
                                        <p className="author-info">SEO Specialist</p>
                                    </div>
                                    <p className="text">Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
                                        nonummy nibh euismod.
                                    </p>
                                    <div className="socials">

                                        <a href="" className="social__item">
                                            <img src="svg/circle-facebook.svg" alt="facebook"/>
                                        </a>

                                        <a href="" className="social__item">
                                            <img src="svg/twitter.svg" alt="twitter"/>
                                        </a>

                                        <a href="" className="social__item">
                                            <img src="svg/google.svg" alt="google"/>
                                        </a>

                                        <a href="" className="social__item">
                                            <img src="svg/youtube.svg" alt="youtube"/>
                                        </a>

                                    </div> 
                                </div>
                            </div>*/}

                            {/* <div className="pagination-arrow">

                                <a href="#" className="btn-prev-wrap">
                                
                                    <div className="btn-content">
                                        <div className="btn-content-title">Next Post</div>
                                        <p className="btn-content-subtitle">Claritas Est Etiam Processus</p>
                                    </div>
                                </a>

                                <a href="#" className="btn-next-wrap">
                                    <div className="btn-content" >
                                        <div className="btn-content-title">Previous Post</div>
                                        <p className="btn-content-subtitle">Duis Autem Velius</p>
                                    </div>
                                    
                                </a>

                            </div> */}

                            {/* <div className="comments">

                                <div className="heading">
                                    <h4 className="h1 heading-title">6 Comments</h4>
                                    <div className="heading-line">
                                        <span className="short-line"></span>
                                        <span className="long-line"></span>
                                    </div>
                                </div>

                                <ol className="comments__list">

                                    <li className="comments__item">

                                        <div className="comment-entry comment comments__article">

                                            <div className="comment-content comment">
                                                <p>Mirum est notare quam littera gothica, quam nunc putamus parum claram,
                                                    anteposuerit litterarum formas humanitatis per seacula quarta
                                                    et quinta decima.
                                                </p>
                                            </div>

                                            <div className="comments__body display-flex">

                                                <a href="#" className="reply">
                                                    <i className=" seoicon-arrow-back"></i>
                                                </a>

                                                <div className="comments__avatar">

                                                    <img src="img/post-author3.png" alt="avatar"/>

                                                </div>

                                                <header className="comment-meta comments__header">

                                                    <cite className="fn url comments__author">
                                                        <a href="#" rel="external" className=" ">Jonathan Simpson</a>
                                                    </cite>

                                                    <div className="comments__time">
                                                        <time className="published" datetime="2016-04-20 12:00:00">20.04.2016
                                                            <span className="at">at</span> 4:27 pm
                                                        </time>
                                                    </div>

                                                </header>

                                            </div>

                                        </div>
                                    </li>

                                    <li className="comments__item">

                                        <div className="comment-entry comment comments__article">

                                            <div className="comment-content comment">
                                                <p>Investigationes demonstraverunt lectores legere me lius quod ii legunt saepius.</p>
                                            </div>

                                            <div className="comments__body display-flex">

                                                <a href="#" className="reply">
                                                    <i className=" seoicon-arrow-back"></i>
                                                </a>

                                                <div className="comments__avatar">

                                                    <img src="img/post-author2.png" alt="avatar"/>

                                                </div>

                                                <header className="comment-meta comments__header">

                                                    <cite className="fn url comments__author">
                                                        <a href="#" rel="external">Angelina Johnson</a>
                                                    </cite>

                                                    <div className="comments__time">
                                                        <time className="published" datetime="2016-04-20 12:00:00">20.04.2016
                                                            <span className="at">at</span> 4:27 pm
                                                        </time>
                                                    </div>

                                                </header>

                                            </div>

                                        </div>

                                        <ol className="children">

                                            <li className="comments__item">

                                                <div className="comment-entry comment comments__article">

                                                    <div className="comment-content comment">
                                                        <p>Mirum est notare quam littera gothica, quam nunc putamus parum claram,
                                                            anteposuerit litterarum formas humanitatis per seacula quarta decima et
                                                            quinta decima facer possim assum.
                                                        </p>
                                                    </div>

                                                    <div className="comments__body display-flex">

                                                        <a href="#" className="reply">
                                                            <i className=" seoicon-arrow-back"></i>
                                                        </a>

                                                        <div className="comments__avatar">

                                                            <img src="img/post-author1.png" alt="avatar"/>

                                                        </div>

                                                        <header className="comment-meta comments__header">

                                                            <cite className="fn url comments__author">
                                                                <a href="#" rel="external">Philip Demarco</a>
                                                                <span className="replied">replied Angelina</span>
                                                            </cite>

                                                            <div className="comments__time">
                                                                <time className="published" datetime="2016-04-20 12:00:00">20.04.2016
                                                                    <span className="at">at</span> 4:27 pm
                                                                </time>
                                                            </div>

                                                        </header>

                                                    </div>

                                                </div>

                                                <ol className="children">

                                                    <li className="comments__item">

                                                        <div className="comment-entry comment comments__article">

                                                            <div className="comment-content comment">
                                                                <p>Eodem modo typi, qui nunc nobis videntur parum clari, fiant sollemnes in futurum.
                                                                </p>
                                                            </div>

                                                            <div className="comments__body display-flex">

                                                                <a href="#" className="reply">
                                                                    <i className=" seoicon-arrow-back"></i>
                                                                </a>

                                                                <div className="comments__avatar">

                                                                    <img src="img/post-author2.png" alt="avatar"/>

                                                                </div>

                                                                <header className="comment-meta comments__header">

                                                                    <cite className="fn url comments__author">
                                                                        <a href="#" rel="external">Angelina Johnson</a>
                                                                        <span className="replied">replied Angelina</span>
                                                                    </cite>

                                                                    <div className="comments__time">
                                                                        <time className="published" datetime="2016-04-20 12:00:00">20.04.2016
                                                                            <span className="at">at</span> 4:27 pm
                                                                        </time>
                                                                    </div>

                                                                </header>

                                                            </div>

                                                        </div>
                                                    </li>

                                                </ol>
                                            </li>

                                        </ol>
                                    </li>

                                    <li className="comments__item">

                                        <div className="comment-entry comment comments__article">

                                            <div className="comment-content comment">
                                                <p>Mirum est notare quam littera gothica, quam nunc putamus parum claram,
                                                    anteposuerit litterarum formas humanitatis per seacula quarta decima et
                                                    quinta decima.
                                                </p>
                                            </div>

                                            <div className="comments__body display-flex">

                                                <a href="#" className="reply">
                                                    <i className=" seoicon-arrow-back"></i>
                                                </a>

                                                <div className="comments__avatar">

                                                    <img src="img/post-author3.png" alt="avatar"/>

                                                </div>

                                                <header className="comment-meta comments__header">

                                                    <cite className="fn url comments__author">
                                                        <a href="#" rel="external" className=" ">Jonathan Simpson</a>
                                                    </cite>

                                                    <div className="comments__time">
                                                        <time className="published" datetime="2016-04-20 12:00:00">20.04.2016
                                                            <span className="at">at</span> 4:27 pm
                                                        </time>
                                                    </div>

                                                </header>

                                            </div>

                                        </div>


                                        <ol className="children">

                                            <li className="comments__item">

                                                <div className="comment-entry comment comments__article">

                                                    <div className="comment-content comment">
                                                        <p>Mirum est notare quam littera gothica, quam nunc putamus parum claram,
                                                            anteposuerit litterarum formas humanitatis per seacula quarta decima et
                                                            quinta decima facer possim assum.
                                                        </p>
                                                    </div>

                                                    <div className="comments__body display-flex">

                                                        <a href="#" className="reply">
                                                            <i className=" seoicon-arrow-back"></i>
                                                        </a>

                                                        <div className="comments__avatar">

                                                            <img src="img/post-author1.png" alt="avatar"/>

                                                        </div>

                                                        <header className="comment-meta comments__header">

                                                            <cite className="fn url comments__author">
                                                                <a href="#" rel="external">Philip Demarco</a>
                                                                <span className="replied">replied Angelina</span>
                                                            </cite>

                                                            <div className="comments__time">
                                                                <time className="published" datetime="2016-04-20 12:00:00">20.04.2016
                                                                    <span className="at">at</span> 4:27 pm
                                                                </time>
                                                            </div>

                                                        </header>

                                                    </div>

                                                </div>
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                            </div> */}

                            {/* <div className="row">

                                <div className="leave-reply contact-form">

                                    <form>

                                        <div className="col-lg-12">
                                            <div className="heading">
                                                <h4 className="h1 heading-title">Leave a Comment</h4>
                                                <div className="heading-line">
                                                    <span className="short-line"></span>
                                                    <span className="long-line"></span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-12">

                                            <input className="email input-standard-grey" name="full_name" id="full_name" placeholder="Your Full Name" value="" type="text"/>

                                        </div>

                                        <div className="col-lg-6">

                                            <input className="email input-standard-grey" name="comment_email" id="comment_email" placeholder="Email Address" value="" type="text"/>

                                        </div>

                                        <div className="col-lg-6">

                                            <input className="email input-standard-grey" name="comment_website" id="comment_website" placeholder="Website" value="" type="text"/>

                                        </div>

                                        <div className="col-lg-12">

                                            <textarea name="order_comments" className="input-text input-standard-grey" id="order_comments" placeholder="Your Comment"></textarea>

                                        </div>

                                        <div className="col-lg-12">

                                            <div className="submit-block table">
                                                <div className="col-lg-4 table-cell">
                                                    <div className="btn btn-small btn--primary">
                                                        <span className="text">Submit</span>
                                                    </div>
                                                </div>

                                                <div className="col-lg-8 table-cell">
                                                    <div className="submit-block-text">
                                                        You may use these HTML tags and attributes: <span> &lt;a href="" title=""&gt; &lt;abbr title=""&gt; &lt;acronym title=""&gt;
                                                    &lt;b&gt; &lt;blockquote cite=""&gt; &lt;cite&gt; &lt;code&gt; &lt;del datetime=""&gt;
                                                    &lt;em&gt; &lt;i&gt; &lt;q cite=""&gt; &lt;strike&gt; &lt;strong&gt; </span>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </form>
                                </div>
                            </div> */}
                        </div>

                        <div className="col-lg-3 col-lg-offset-1 col-md-4 col-sm-12 col-xs-12">
                             <aside aria-label="sidebar" className="sidebar sidebar-right">
                            {/*    <div className="widget">
                                    <form className="w-search">
                                        <input className="email search input-standard-grey" required="required" placeholder="Search" type="search"/>
                                        <button className="icon">
                                            <i className="seoicon-loupe"></i>
                                        </button>
                                    </form>
                                </div>

                                <div className="widget w-post-category">
                                    <div className="heading">
                                        <h4 className="heading-title">Post Category</h4>
                                        <div className="heading-line">
                                            <span className="short-line"></span>
                                            <span className="long-line"></span>
                                        </div>
                                    </div>
                                    <div className="post-category-wrap">
                                        <div className="category-post-item">
                                            <span className="post-count">168</span>
                                            <a href="#" className="category-title">SEO
                                                <i className="seoicon-right-arrow"></i>
                                            </a>
                                        </div>

                                        <div className="category-post-item">
                                            <span className="post-count">52</span>
                                            <a href="#" className="category-title">Local SEO
                                                <i className="seoicon-right-arrow"></i>
                                            </a>
                                        </div>

                                        <div className="category-post-item">
                                            <span className="post-count">40</span>
                                            <a href="#" className="category-title">Social Media Marketing
                                                <i className="seoicon-right-arrow"></i>
                                            </a>
                                        </div>

                                        <div className="category-post-item">
                                            <span className="post-count">33</span>
                                            <a href="#" className="category-title">Email Marketing
                                                <i className="seoicon-right-arrow"></i>
                                            </a>
                                        </div>

                                        <div className="category-post-item">
                                            <span className="post-count">21</span>
                                            <a href="#" className="category-title">PPC Management
                                                <i className="seoicon-right-arrow"></i>
                                            </a>
                                        </div>

                                        <div className="category-post-item">
                                            <span className="post-count">18</span>
                                            <a href="#" className="category-title">Technology
                                                <i className="seoicon-right-arrow"></i>
                                            </a>
                                        </div>

                                        <div className="category-post-item">
                                            <span className="post-count">5</span>
                                            <a href="#" className="category-title">Business
                                                <i className="seoicon-right-arrow"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div className="widget w-about">
                                    <div className="heading">
                                        <h4 className="heading-title">About Us and
                                            This Blog</h4>
                                        <div className="heading-line">
                                            <span className="short-line"></span>
                                            <span className="long-line"></span>
                                        </div>
                                        <p>Mirum est notare quam littera gothica, quam nunc putamus parum claram, anteposuerit
                                            litterarum formas humanitatis per seacula quarta decima quinta.
                                        </p>
                                    </div>

                                    <a href="02_abouts.html" className="btn btn-small btn-border c-primary">
                                        <span className="text">Learn More</span>
                                        <i className="seoicon-right-arrow"></i>
                                    </a>
                                </div>

                                <div className="widget w-request bg-boxed-red">
                                    <div className="w-request-content">
                                        <img src="img/request.png" alt="request"/>
                                        <h4 className="w-request-content-title">Request
                                            a Free Quote</h4>
                                        <p className="w-request-content-text">Gothica, quam nunc putamus parum claram, anteposuerit
                                            litterarum formas humanitatis.
                                        </p>

                                        <a href="22_contacts.html" className="btn btn-small btn--dark btn-hover-shadow">
                                            <span className="text">Contact Now</span>
                                        </a>
                                    </div>
                                </div>

                                <div className="widget w-latest-news">
                                    <div className="heading">
                                        <h4 className="heading-title">Latest News</h4>
                                        <div className="heading-line">
                                            <span className="short-line"></span>
                                            <span className="long-line"></span>
                                        </div>
                                    </div>

                                    <div className="latest-news-wrap">
                                        <div className="latest-news-item">
                                            <div className="post-additional-info">

                                        <span className="post__date">

                                            <i className="seoicon-clock"></i>

                                            <time className="published" datetime="2016-04-23 12:00:00">
                                                April 23, 2016
                                            </time>

                                        </span>

                                            </div>

                                            <h5 className="post__title entry-title ">
                                                <a href="15_blog_details.html">Eodem modo typi, qui nunc nobis videntur</a>
                                            </h5>
                                        </div>

                                        <div className="latest-news-item">
                                            <div className="post-additional-info">

                                        <span className="post__date">

                                            <i className="seoicon-clock"></i>

                                            <time className="published" datetime="2016-04-08 12:00:00">
                                                April 8, 2016
                                            </time>

                                        </span>

                                            </div>

                                            <h5 className="post__title entry-title ">
                                                <a href="15_blog_details.html">Investigationes demonstraverunt lectores legere</a>
                                            </h5>
                                        </div>

                                        <div className="latest-news-item">
                                            <div className="post-additional-info">

                                        <span className="post__date">

                                            <i className="seoicon-clock"></i>

                                            <time className="published" datetime="2016-03-25 12:00:00">
                                                March 25, 2016
                                            </time>

                                        </span>

                                            </div>

                                            <h5 className="post__title entry-title ">
                                                <a href="15_blog_details.html">Qemonstraverunt legere</a>
                                            </h5>
                                        </div>

                                        <div className="latest-news-item">
                                            <div className="post-additional-info">

                                        <span className="post__date">

                                            <i className="seoicon-clock"></i>

                                            <time className="published" datetime="2016-03-12 12:00:00">
                                                March 12, 2016
                                            </time>

                                        </span>

                                            </div>

                                            <h5 className="post__title entry-title ">
                                                <a href="15_blog_details.html">Ut wisi enim ad minim veniam, quis nostrud exerci</a>
                                            </h5>
                                        </div>

                                    </div>

                                    <a href="14_blog.html" className="btn btn-small btn--dark btn-hover-shadow">
                                        <span className="text">All News</span>
                                        <i className="seoicon-right-arrow"></i>
                                    </a>
                                </div>

                                <div className="widget w-follow">
                                    <div className="heading">
                                        <h4 className="heading-title">Follow Us</h4>
                                        <div className="heading-line">
                                            <span className="short-line"></span>
                                            <span className="long-line"></span>
                                        </div>
                                    </div>

                                    <div className="w-follow-wrap">
                                        <div className="w-follow-item facebook-bg-color">
                                            <a href="#" className="w-follow-social__item table-cell">
                                                <i className="seoicon-social-facebook"></i>
                                            </a>
                                            <a href="#" className="w-follow-title table-cell">Facebook
                                                <span className="w-follow-add">
                                            <i className="seoicon-cross plus"></i>
                                            <i className="seoicon-check-bold check"></i>
                                        </span>
                                            </a>
                                        </div>
                                        <div className="w-follow-item twitter-bg-color">
                                            <a href="#" className="w-follow-social__item table-cell">
                                                <i className=" seoicon-social-twitter"></i>
                                            </a>
                                            <a href="#" className="w-follow-title table-cell">Twitter
                                                <span className="w-follow-add active">
                                            <i className="seoicon-cross plus"></i>
                                            <i className="seoicon-check-bold check"></i>
                                        </span>
                                            </a>
                                        </div>
                                        <div className="w-follow-item linkedin-bg-color">
                                            <a href="#" className="w-follow-social__item table-cell">
                                                <i className="seoicon-social-linkedin"></i>
                                            </a>
                                            <a href="#" className="w-follow-title table-cell">Linkedin
                                                <span className="w-follow-add">
                                            <i className="seoicon-cross plus"></i>
                                            <i className="seoicon-check-bold check"></i>
                                        </span>
                                            </a>
                                        </div>
                                        <div className="w-follow-item google-bg-color">
                                            <a href="#" className="w-follow-social__item table-cell">
                                                <i className="seoicon-social-google-plus"></i>
                                            </a>
                                            <a href="#" className="w-follow-title table-cell">Google+
                                                <span className="w-follow-add">
                                            <i className="seoicon-cross plus"></i>
                                            <i className="seoicon-check-bold check"></i>
                                        </span>
                                            </a>
                                        </div>
                                        <div className="w-follow-item pinterest-bg-color">
                                            <a href="#" className="w-follow-social__item table-cell">
                                                <i className="seoicon-social-pinterest"></i>
                                            </a>
                                            <a href="#" className="w-follow-title table-cell">Pinterest
                                                <span className="w-follow-add">
                                            <i className="seoicon-cross plus"></i>
                                            <i className="seoicon-check-bold check"></i>
                                        </span>
                                            </a>
                                        </div>
                                    </div>

                                </div>*/}

                                <div className="widget w-tags">
                                    <div className="heading">
                                        <h4 className="heading-title">Popular Tags</h4>
                                        <div className="heading-line">
                                            <span className="short-line"></span>
                                            <span className="long-line"></span>
                                        </div>
                                    </div>

                                    <div className="tags-wrap">
                                        <li href="#" className="w-tags-item">SEO</li>
                                        <li href="#" className="w-tags-item">Advertising</li>
                                        <li href="#" className="w-tags-item">Business</li>
                                        <li href="#" className="w-tags-item">Optimization</li>
                                        <li href="#" className="w-tags-item">Digital Marketing</li>
                                        <li href="#" className="w-tags-item">Social</li>
                                        <li href="#" className="w-tags-item">Keyword</li>
                                        <li href="#" className="w-tags-item">Strategy</li>
                                        <li href="#" className="w-tags-item">Audience</li>
                                    </div>
                                </div>
                            </aside> 
                        </div>

                        

                    </main>
                </div>
            </div>                  
        </>
    )
}

export default BlogMainPage;
