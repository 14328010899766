import React, { Component } from 'react'
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import '../Portfolio1.css'; 

const TabThree = [
    {
        image: 'tipster_challenge',
        bigImage: '../img/portfolio1/main/dp-portfolio-tipster_challenge.png',
        category: 'PHP',
        websiteName:'TIPSTER CHALLENGE',
        title: 'This is a Global and Innovative FREE Horse Racing, Competition based on UK Horse Racing',
		siteurl:'http://tipsterchallenge.com/'
    },
    {
        image: 'wecharge',
        bigImage: '../img/portfolio1/main/dp-portfolio-wecharge.png',
        category: 'PHP',
        websiteName:'WECHARGE',
        title: 'Fuel and Diesel refueliing website with four panel and mobile app',
		siteurl:'https://wecharge.co.in/'
    },
]


class Php extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab3: 0,
            isOpen: false,
        };
    }
    render() {
        const { tab3, isOpen } = this.state;
        return (
           <>
            <div className="row row--35">
                        {TabThree.map((value , index) => (
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={index}>
                                {isOpen && (
                                    <Lightbox
                                        mainSrc={TabThree[tab3].bigImage}
                                        nextSrc={TabThree[(tab3 + 1) % TabThree.length]}
                                        prevSrc={TabThree[(tab3 + TabThree.length - 1) % TabThree.length]}
                                        onCloseRequest={() => this.setState({ isOpen: false })}
                                        onMovePrevRequest={() =>
                                        this.setState({
                                            tab3: (tab3 + TabThree.length - 1) % TabThree.length,
                                        })
                                        }
                                        onMoveNextRequest={() =>
                                            this.setState({
                                                tab3: (tab3 + 1) % TabThree.length,
                                            })
                                        }
                                        imageLoadErrorMessage = 'Image Loading ...'
                                        enableZoom={false}
                                    />
                                )}
                                 <div className="item-portfolio-static">
                                    <div onClick={() => this.setState({ isOpen: true, tab3: index })}>
                                        <div className="portfolio-static case-item align-center mb60">
                                            <div className="thumbnail-inner case-item__thumb">
                                                <div className="thumbnails">
                                                    <a href="#portfolio-details">
                                                        <img src={`../img/portfolio1/main/dp-portfolio-${value.image}.png`} alt="Portfolio img"/>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <div className="inner">
                                                <p><a href={value.siteurl} className='case-item__title'  target="_blank" rel="noopener noreferrer">{value.websiteName}</a></p>
                                                    <h6 className='case-item__title' >{value.title}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

           </>
        )
    }
}
export default Php;